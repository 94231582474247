time.icon {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fff;
  margin: 0;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  overflow: hidden;
}

time.icon * {
  display: block;
  width: 100%;
  font-weight: 500;
  color: #000;
  font-style: normal;
  text-align: center;
}

time.icon .month {
  text-transform: uppercase;
  font-size: 12px;
  color: var(--alt-text-color);
  background-color: var(--alt-background-color);
  padding: 3px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

time.icon .day {
  font-size: 22px;
  line-height: 30px;
  color: inherit;
  background-color: #fff;
  padding: 0;
  padding-top: 5px;
}

time.icon .week {
  font-size: 10px;
  line-height: 10px;
  color: inherit;
  background-color: #fff;
  padding: 5px 0;
  padding-bottom: 10px;
}

.icon.day {
  background-color: #24ada0;
  box-shadow: 0 0.5px 0 #000, 0 0.5px 0 #1f518b, 0 0 0 0.5px #000;
  color: inherit;
}

/* .icon.day {
  background-color: #1f518b;
  box-shadow: 0 0.5px 0 #fff, 0 0.5px 0 #1f518b, 0 0 0 0.5px #fff;
  color: #fff !important;
} */
