.paralsec1-photo {
  background: transparent;
}

.photos-container {
  background-color: white;
  border-radius: 5px;
  padding: 40px;
  margin: 20px;
}

.quote {
  display: block;
  margin: 10px;
}

.sliderNav .slider-image {
  margin: 10px;
  padding: 10px;
  height: 180px;
  width: auto;
  background-size: cover;
}
.sliderNav .slick-slide {
  width: 220px;
}

.sliderFor .slider-image {
  width: 100%;
  height: 300px;
  padding: 10px;
}

.sliderNav {
  background-color: #fff;
  border-radius: 5px;
  padding: 1px 0px;
}

.sliderFor {
  background-color: #fff;
  border-radius: 5px;
  padding: 5px 5px 10px 5px;
  margin-bottom: 20px;
}

.sliderFor .slider-image {
  margin: 10px;
  padding: 10px;
  height: 420px;
  width: auto;
  background-size: cover;
}

.react-images__positioner {
  z-index: 22000 !important;
}
.react-images__blanket {
  z-index: 21000 !important;
}

.react-photo-gallery--gallery div > img:hover {
  transform: scale(1.1);
  z-index: 2;
}

.react-photo-gallery--gallery div > img:hover {
  transition: transform 0.5s;
  will-change: transform;
}

.react-photo-gallery--gallery div > img {
  overflow: hidden;
}

.photos {
  display: grid;
  overflow: hidden;
  position: relative;
  grid-auto-flow: column;
  max-width: 100vw;
  z-index: 23;
  background-image: linear-gradient(
    to top,
    rgba(163, 163, 163, 0.769),
    rgba(119, 119, 119, 0.475)
  );
  background-color: rgba(0, 0, 0, 0.4);
}

.photo-bg {
  min-width: 300px;
  height: 320px;
  filter: blur(2px);
}

.photos-component {
  position: relative;
  height: 330px !important;
  z-index: 1;
  padding: 10px 0px 0px 0px !important;
}

.overlay {
  background-image: linear-gradient(to top, rgb(0, 0, 0), rgba(0, 0, 0, 1));
  bottom: 0;
  left: 0;
  opacity: 0.5;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 34;
}
