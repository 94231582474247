.transition,
.safety-tips {
  p,
  ul li i:before,
  ul li i:after {
    transition: all 0.25s ease-in-out;
  }

  .flipIn,
  h1,
  ul li {
    animation: flipdown 0.5s ease both;
  }

  .no-select,
  h2 {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  @media (max-width: 550px) {
    body {
      box-sizing: border-box;
      transform: translate(0, 0);
      max-width: 100%;
      min-height: 100%;
      margin: 0;
      left: 0;
    }
  }

  h1,
  h2 {
    color: var(--main-text-color);
  }

  h1 {
    text-transform: uppercase;
    font-size: 36px;
    line-height: 42px;
    letter-spacing: 3px;
    font-weight: 100;
  }

  h2 {
    font-size: 26px;
    line-height: 34px;
    font-weight: 300;
    letter-spacing: 1px;
    display: block;
    background-color: #fefffa;
    margin: 0;
    cursor: pointer;
  }

  p {
    color: rgba(48, 69, 92, 0.8);
    font-size: 17px;
    line-height: 26px;
    letter-spacing: 1px;
    position: relative;
    overflow: hidden;
    opacity: 1;
    transform: translate(0, 0);
    margin-top: 14px;
    z-index: 2;
  }

  ul {
    list-style: none;
    perspective: 900;
    padding: 0;
    margin: 0;
  }

  ul li {
    position: relative;
    padding: 0;
    margin: 0;
    padding-bottom: 4px;
    padding-top: 18px;
    border-top: 1px dotted #dce7eb;
  }

  ul li:nth-of-type(1) {
    animation-delay: 0.5s;
  }

  ul li:nth-of-type(2) {
    animation-delay: 0.75s;
  }

  ul li:nth-of-type(3) {
    animation-delay: 1s;
  }

  ul li:last-of-type {
    padding-bottom: 0;
  }

  ul li i {
    position: absolute;
    transform: translate(-6px, 0);
    margin-top: 16px;
    margin-right: 10px;
    right: 0;
  }

  ul li i:before,
  ul li i:after {
    content: '';
    position: absolute;
    background-color: var(--alt-background-color);
    width: 3px;
    height: 18px;
  }

  ul li i:before {
    transform: translate(-1px, 0) rotate(45deg);
  }

  ul li i:after {
    transform: translate(10px, 0) rotate(-45deg);
  }

  ul li input[type='checkbox'] {
    position: absolute;
    cursor: pointer;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
  }

  .hide ~ p {
    margin-top: 0;
    max-height: 0;
    opacity: 0;
    transform: translate(0, 50%);
  }

  .hide:before {
    transform: translate(1px, 0) rotate(45deg);
  }

  .hide:after {
    transform: translate(-10px, 0) rotate(-45deg);
  }

  @keyframes flipdown {
    0% {
      opacity: 0;
      transform-origin: top center;
      transform: rotateX(-90deg);
    }

    5% {
      opacity: 1;
    }

    80% {
      transform: rotateX(8deg);
    }

    83% {
      transform: rotateX(6deg);
    }

    92% {
      transform: rotateX(-3deg);
    }

    100% {
      transform-origin: top center;
      transform: rotateX(0deg);
    }
  }
  .clickable-icon {
    cursor: pointer;
  }

  .tip-icon {
    max-width: 24px !important;
    max-height: 24px !important;
    width: 24px !important;
    font-size: 24px;
    color: #fff;
  }

  .tip-icon-container {
    padding: 10px;
    background-color: var(--alt-background-color);
    border-radius: 50%;
  }

  .tip-header {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    transition: 0.5s;
    margin-top: 5px;
  }
}
.tip-text {
  max-width: fit-content;
}

.safety-header {
  background: -webkit-linear-gradient(
      rgba(0, 0, 0, 0.476),
      rgba(39, 39, 39, 0.615)
    ),
    url('/assets/images/header/safety-bg.jpg') no-repeat center center !important;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  background-size: cover !important;
  -o-background-size: cover !important;
}
