.post-header {
  background-color: transparent !important;
  color: #fff;
  height: 100px;
}

.img-post {
  border-radius: 3px;
  border: 0.1px solid !important;
}

.post-card {
  border-radius: 3px;
  background-color: #fff;
  padding: 0px !important;
}

.other-articles {
  background-color: #fff;
  border-radius: 3px;
  display: flex;
  text-decoration: none !important;
  color: #1b1b1b;
  cursor: pointer;
}
