@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900');

body {
  font-family: 'Lato', sans-serif;
  box-sizing: border-box;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 75px;
  background: #000000;
  z-index: 10;
}

header section {
  max-width: 1300px;
  margin: auto;
  padding: 20px;
  background: #ffd800;
}

@media (max-width: 1515px) {
  header section {
    max-width: 100%;
  }
}

header section .logo {
  transform: translateX(-87px);
}

header section a {
  float: right;
  padding: 3px 0;
}

* {
  outline: none;
}

.button-styled {
  -webkit-appearance: none;
  appearance: none;
  -webkit-filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all 0.5s ease;
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  border: none;
  background: var(--alt-color);
  border-radius: 30px;
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 15px 40px;
  font-weight: 400;
  font-size: 13px;
  cursor: cursor;
  z-index: 3123;
}

.button-styled:hover {
  color: var(--alt-color);
  background: var(--main-text-color);
  -webkit-filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
}

.wrapper {
  max-width: 1335px;
  box-sizing: border-box;
  padding: 0 20px;
  margin: auto;
}

.wrapper a {
  display: inline-block;
  margin: 5px;
}

.wrapper a:first-child {
  margin-left: 0;
}

.slider-home .slider-image {
  width: 100%;
  height: 550px;
  box-sizing: border-box;
  object-fit: cover;
  background-size: cover;
  background-repeat: no-repeat;
}

.slider-home .slider-text {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 700px;
}

/* .slick-slide {
  width: 100%;
} */
.slider-home .slick-track {
  height: 700px;
  width: 100%;
}

.slider-home .slick-dots li button:before {
  color: #ececec;
}

.slider-home .slick-dots li.slick-active {
  color: #fff !important;
}

.slider-home .slick-dots {
  bottom: 25px;
  display: flex !important;
  justify-content: center;
}
.slider-home .slick-dots li.slick-active button:before {
  color: #fff !important;
}

.slider-home .slick-arrow {
  z-index: 10;
}

.slider-home .slick-next {
  right: 15px;
}

.slider-home .slick-prev {
  left: 15px;
}

.flex-inner {
  min-width: 0;
  max-height: 0;
}
.flex-inner {
  flex: 1;
}

.slider-home .slider-container {
  max-width: 100%;
}

.slider-home .slick-active .button-styled {
  color: #fff !important;
  display: inline;
}
.slider-home .slick-active {
  color: #fff !important;
  display: inline;
  z-index: 123;
}
.slider-home .button-styled {
  display: none;
}
.slider-home .slider-image {
  z-index: -13;
}
