/* ===================================
--------------------------------------
  TheQuest - Gaming Magazine Template
  Version: 1.0
--------------------------------------
======================================*/

/*----------------------------------------*/
/* Template default CSS
/*----------------------------------------*/

html,
body {
  height: 100%;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  color: #111111;
  font-weight: 700;
}

h1 {
  font-size: 70px;
  font-family: Oswald, Roboto;
}

h2 {
  font-size: 36px;
  font-family: Oswald, Roboto;
}

h3 {
  font-size: 30px;
  font-family: Oswald, Roboto;
}

h4 {
  font-size: 24px;
  font-family: Oswald, Roboto;
}

h5 {
  font-size: 18px;
  font-family: Oswald, Roboto;
}

h6 {
  font-size: 16px;
  font-family: Oswald, Roboto;
}

p {
  font-size: 16px;
  color: #161616;
  line-height: 1.8;
}

img {
  max-width: 100%;
}

input:focus,
select:focus,
button:focus,
textarea:focus {
  outline: none;
}

a:hover,
a:focus {
  text-decoration: none;
  outline: none;
}

ul,
ol {
  padding: 0;
  margin: 0;
}

:root {
  --main-background-color: #e4e4e4;
  --main-text-color: #3d3d3d;
  --alt-text-color: #fff;
  --alt-color: #025892;
  --alt-background-color: #ab3b6b;
}

.alt-text-color {
  color: var(--alt-text-color);
}

.alt-background-color {
  background-color: var(--alt-background-color);
}

.navbar {
  background-color: #fff !important;
}

a {
  text-decoration: none !important;
}
/*---------------------
Helper CSS
 -----------------------*/

/* .main-color {
  color: var(--main-text-color);
} */

#root {
  background: var(--main-background-color);
  height: 100%;
}

.section-title {
  text-align: center;
}

.section-title h2 {
  font-size: 36px;
}

.set-bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}

.spad {
  padding-top: 106px;
  padding-bottom: 106px;
}

.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p,
.text-white span,
.text-white li,
.text-white a {
  color: #fff;
}

/*---------------------
Commom elements
 -----------------------*/

/* buttons */

.site-btn {
  display: inline-block;
  border: none;
  font-size: 14px;
  font-weight: 600;
  min-width: 164px;
  padding: 21px 47px;
  border-radius: 50px;
  text-transform: uppercase;
  background: var(--alt-background-color);
  color: var(--alt-text-color);
  line-height: 1;
  cursor: pointer;
  text-align: center;
}

.site-btn:hover {
  color: #c0c0c0;
}

.site-btn.sb-color {
  background: #ff1f56;
  color: #fff;
}

.site-btn.sb-color:hover {
  color: #fff;
}

/* Preloder */

#preloder {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999999;
  background: #000;
}

.loader {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -13px;
  margin-left: -13px;
  border-radius: 60px;
  animation: loader 0.8s linear infinite;
  -webkit-animation: loader 0.8s linear infinite;
}

@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    border: 4px solid #f44336;
    border-left-color: transparent;
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    border: 4px solid #673ab7;
    border-left-color: transparent;
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    border: 4px solid #f44336;
    border-left-color: transparent;
  }
}

@-webkit-keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    border: 4px solid #f44336;
    border-left-color: transparent;
  }
  50% {
    -webkit-transform: rotate(180deg);
    border: 4px solid #673ab7;
    border-left-color: transparent;
  }
  100% {
    -webkit-transform: rotate(360deg);
    border: 4px solid #f44336;
    border-left-color: transparent;
  }
}
.bg-dark {
  background-color: #353535;
}
/*------------------
Header section
 ---------------------*/

.header-section {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  background: #353535;
  z-index: 9999;
}

.site-logo {
  display: inline-block;
  padding: 21px 55px 20px;
  background: #222222;
}

.main-menu {
  display: inline-block;
  padding-left: 44px;
  position: relative;
  z-index: 99;
  background: #353535;
}

.main-menu li {
  display: inline-block;
}

.main-menu li a {
  position: relative;
  display: block;
  padding: 44px 7px;
  margin-right: 55px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

.main-menu li a:after {
  position: absolute;
  content: '';
  width: 0;
  height: 3px;
  left: 0;
  bottom: 0;
  background: #10ddb4;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.main-menu li a:hover:after {
  width: 100%;
}

.slicknav_menu {
  display: none;
}

.header-add {
  position: absolute;
  right: 0;
  top: 0;
  width: 43%;
  height: 100%;
  text-align: right;
}

.header-add img {
  height: 100%;
}

/*------------------
   Hero-section
 ---------------------*/

.hero-item {
  height: 804px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  position: relative;
  padding-top: 140px;
}

.hero-item:after {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #0d0d11;
  opacity: 0.74;
  z-index: 1;
}

.hero-item .container {
  position: relative;
  z-index: 9;
}

.hero-item h2 {
  font-size: 96px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 15px;
}

.hero-item p {
  font-size: 20px;
  margin-bottom: 35px;
}

.hero-item .site-btn,
.hero-item p,
.hero-item h2 {
  position: relative;
  top: 50px;
  opacity: 0;
}

.hero-slider .owl-item.active .hero-item h2,
.hero-slider .owl-item.active .hero-item p,
.hero-slider .owl-item.active .hero-item .site-btn {
  top: 0;
  opacity: 1;
}

.hero-slider .owl-item.active .hero-item h2 {
  -webkit-transition: all 0.5s ease 0.4s;
  transition: all 0.5s ease 0.4s;
}

.hero-slider .owl-item.active .hero-item p {
  -webkit-transition: all 0.5s ease 0.6s;
  transition: all 0.5s ease 0.6s;
}

.hero-slider .owl-item.active .hero-item .site-btn {
  -webkit-transition: all 0.5s ease 0.8s;
  transition: all 0.5s ease 0.8s;
}

/*------------------
   Blog-section
 ---------------------*/

/* ----------------
Responsive
 ---------------------*/

.characters-section {
  padding: 144px 0 118px;
  overflow: hidden;
  position: relative;
}

.character-info img {
  margin-bottom: 3px;
}

.character-info h2 {
  font-size: 48px;
  color: #fff;
  margin-bottom: 15px;
}

.character-info h5 {
  color: #fff;
  margin-bottom: 9px;
}

.character-info p {
  padding-top: 30px;
  margin-bottom: 40px;
}

.circle-progress {
  text-align: center;
  display: block;
  position: relative;
  margin-bottom: 5px;
}

.circle-progress canvas {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.circle-progress .progress-value {
  position: absolute;
  width: 100%;
  top: 50%;
  margin-top: -12px;
}

.cp-item {
  display: inline-block;
  text-align: center;
  margin-right: 50px;
  margin-left: 10px;
  margin-bottom: 40px;
}

.cp-item h3 {
  font-size: 14px;
  font-weight: 400;
  color: #10ddb4;
}

.cp-item h4 {
  font-size: 14px;
  font-weight: 400;
  color: #8c8c8c;
}

.character-img {
  position: absolute;
}

.character-one {
  background: #1c1c1c;
}

.character-one .character-img {
  bottom: -180px;
  right: 0;
  width: 42%;
  text-align: right;
}

.character-two {
  background: #2d2d2d;
}

.character-two .character-img {
  bottom: -285px;
  left: 0;
  width: 68%;
}

.character-three {
  background: #000;
  background: #1c1c1c;
}

.character-three .character-img {
  bottom: 0;
  right: 0;
  width: 50%;
  text-align: right;
}

.character-four {
  background: #2d2d2d;
}

.character-four .character-img {
  bottom: -145px;
  left: 0;
  width: 50%;
}

.banner-section {
  padding: 90px 0 80px;
}

.banner-text {
  max-width: 550px;
  text-align: center;
}

.banner-text .banner-logo {
  margin-bottom: 38px;
}

.banner-text .banner-logo h6 {
  margin-bottom: 25px;
}

.banner-text p {
  margin-bottom: 45px;
}

/* ------------
Game Page
 ---------------*/

.game-section {
  background: #1c1c1c;
  padding: 118px 0 60px;
  overflow: hidden;
}

.game-title {
  margin-bottom: 75px;
}

.game-title h2 {
  font-size: 48px;
  color: #fff;
  padding-top: 5px;
}

.about-game .rating {
  font-size: 17px;
  color: #ffd200;
  margin-bottom: 30px;
  padding-top: 5px;
}

.about-game .rating i {
  display: inline-block;
  margin-right: 4px;
}

.about-game p {
  margin-bottom: 70px;
}

.about-game-img {
  text-align: center;
  width: 570px;
  position: relative;
  top: -30px;
}

.characters-boxes-section {
  background: #000000;
  padding: 113px 0 90px;
}

.characters-box {
  margin-bottom: 30px;
}

.characters-box img {
  margin-bottom: 35px;
  min-width: 100%;
}

.characters-box h4 {
  color: #fff;
  margin-bottom: 20px;
}

.characters-box p {
  margin-bottom: 25px;
}

.characters-box .rm {
  color: #10ddb4;
  font-size: 16px;
  font-weight: 700;
  font-style: italic;
}

.work-steps-section {
  background: #1c1c1c;
  padding: 118px 0 120px;
  overflow: hidden;
}

.work-steps {
  position: relative;
}

.work-steps:after {
  position: absolute;
  content: '';
  max-width: 1127px;
  width: 100%;
  height: 152px;
  left: 25px;
  top: 25px;
  background-image: url('../images/work-line.png');
  background-repeat: no-repeat;
  background-size: contain;
}

.work-step-box {
  text-align: center;
  padding-top: 40px;
  position: relative;
  z-index: 2;
}

.work-step-box .ws-icon {
  width: 147px;
  height: 147px;
  margin: 0 auto 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #000;
  border: 2px solid #10ddb4;
  border-radius: 50%;
}

.work-step-box h4 {
  color: #fff;
  margin-bottom: 25px;
}

.work-step-box p {
  margin-bottom: 35px;
}

.work-step-box ul {
  list-style: none;
}

.work-step-box ul li {
  font-size: 16px;
  color: rgba(140, 140, 140, 0.5);
  margin-bottom: 15px;
}

.work-step-box ul li img {
  margin-right: 12px;
}

.testimonials-section {
  padding: 125px 0;
  background: #222222;
  overflow: hidden;
}

.testimonial-slider {
  margin: 0 -39px;
  width: auto;
}

.testimonial-slider .owl-dots {
  position: absolute;
  right: 35px;
  bottom: 20px;
}

.testimonial-slider .owl-dots .owl-dot {
  width: 9px;
  height: 9px;
  background: #7a7e7d;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 11px;
}

.testimonial-slider .owl-dots .owl-dot:last-child {
  margin-right: 0;
}

.testimonial-slider .owl-dots .owl-dot.active {
  background: #10ddb4;
}

.owl-item.active .testimonial-info {
  opacity: 1;
  padding-left: 39px;
  -webkit-transition: all 0.4s ease 0.6s;
  transition: all 0.4s ease 0.6s;
}

.testimonial-text {
  background: #fff;
  padding: 48px 115px 55px 78px;
  margin-bottom: 41px;
  position: relative;
}

.testimonial-text:after {
  position: absolute;
  content: '';
  left: 46px;
  bottom: -2px;
  width: 50px;
  height: 45px;
  background: #fff;
  -webkit-transform: rotate(50deg) skew(10deg);
  transform: rotate(50deg) skew(10deg);
}

.testimonial-text .test-date {
  display: inline-block;
  width: 143px;
  height: 37px;
  padding-top: 8px;
  margin-bottom: 45px;
  margin-left: -10px;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  border-radius: 50px;
  background: #10ddb4;
}

.testimonial-text h4 {
  margin-bottom: 20px;
}

.testimonial-text p {
  margin-bottom: 0;
}

.testimonial-info {
  padding-left: 59px;
  opacity: 0;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.testimonial-info img {
  float: left;
  width: 68px !important;
  height: 68px;
  margin-right: 17px;
  border: 2px solid #10ddb4;
  border-radius: 50%;
}

.testimonial-info .ti-text {
  padding-top: 20px;
}

.testimonial-info h5 {
  color: #10ddb4;
  font-style: italic;
  line-height: 1;
}

/* ----------------
Responsive
 ---------------------*/

@media only screen and (min-width: 1200px) and (max-width: 1360px) {
  .main-menu li a {
    margin-right: 30px;
  }
  .header-add {
    width: 35%;
  }
  .character-two .character-img {
    bottom: -100px;
  }
  .character-three .character-img {
    width: 40%;
  }
  .character-one .character-img,
  .character-four .character-img {
    bottom: 0;
  }
}

/* Medium screen : 992px. */

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu {
    padding-left: 15px;
  }
  .main-menu li a {
    margin-right: 15px;
  }
  .header-add {
    width: 35%;
  }
  .social-links a {
    margin-right: 60px;
  }
  .character-one .character-img,
  .character-two .character-img,
  .character-four .character-img {
    bottom: 0;
  }
  .character-three .character-img {
    width: 44%;
  }
}

/* Tablet :768px. */

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .site-logo {
    padding: 21px 30px 20px;
  }
  .main-menu {
    padding-left: 15px;
  }
  .main-menu li a {
    margin-right: 15px;
  }
  .header-add {
    display: none;
  }
  .hero-item h2 {
    font-size: 70px;
  }
  .blog-section .blog-posts {
    margin-bottom: 0;
  }
  .small-blog-list,
  .site-pagination {
    margin-bottom: 70px;
  }
  .sb-cata-list {
    width: 360px;
  }
  .social-links a {
    margin-right: 12px;
  }
  .about-game-img {
    width: auto;
    top: 0;
    padding-top: 50px;
  }
  .work-steps:after {
    left: 0px;
    top: 55px;
  }
  .work-step-box h4 {
    font-size: 18px;
  }
  .characters-section {
    padding-bottom: 0;
  }
  .character-one .character-img,
  .character-two .character-img,
  .character-three .character-img,
  .character-four .character-img {
    position: relative;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    padding-top: 70px;
  }
  .blog-post.single-post {
    margin-bottom: 70px;
  }
}

/* Large Mobile :480px. */

@media only screen and (max-width: 767px) {
  .site-logo {
    padding: 20px 15px;
  }
  .main-menu,
  .header-add {
    display: none;
  }
  .header-section {
    background: #222222;
  }
  .slicknav_menu {
    display: block;
    background: transparent;
    padding: 0;
    margin-top: -43px;
  }
  .slicknav_nav {
    background: #353535;
  }
  .slicknav_nav ul {
    margin: 0;
  }
  .slicknav_nav .slicknav_row,
  .slicknav_nav a {
    padding: 10px 20px;
    margin: 0;
  }
  .slicknav_nav a:hover {
    border-radius: 0;
  }
  .slicknav_nav .slicknav_row:hover {
    border-radius: 0;
  }
  .slicknav_btn {
    margin: 0 0 10px 0;
    position: relative;
    top: -31px;
    right: 15px;
    background: #353535;
  }
  .hero-item h2 {
    font-size: 70px;
  }
  .blog-section .blog-posts {
    margin-bottom: 0;
  }
  .small-blog-list,
  .site-pagination {
    margin-bottom: 70px;
  }
  .copyright {
    text-align: center;
  }
  .social-links {
    text-align: center;
  }
  .social-links a {
    margin-right: 20px;
  }
  .social-links a i {
    margin-right: 0;
  }
  .social-links a span {
    display: none;
  }
  .page-top-section h2 {
    font-size: 55px;
  }
  .about-game-img {
    width: auto;
    top: 0;
    padding-top: 50px;
  }
  .work-steps:after {
    display: none;
  }
  .testimonial-slider {
    margin: 0;
  }
  .testimonial-text {
    padding: 48px 40px 55px;
  }
  .characters-section {
    padding-bottom: 0;
  }
  .character-one .character-img,
  .character-two .character-img,
  .character-three .character-img,
  .character-four .character-img {
    position: relative;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    padding-top: 70px;
  }
  .blog-post.single-post {
    margin-bottom: 70px;
  }
}

/* Small Mobile :320px. */

@media only screen and (max-width: 479px) {
  .hero-item h2 {
    font-size: 50px;
  }
  .small-blog-list .sb-item img,
  .comments-list img {
    float: none;
    margin-bottom: 15px;
  }
  .page-top-section h2 {
    font-size: 45px;
  }
  .testimonial-slider .owl-dots {
    position: relative;
    bottom: 0;
    right: 0;
    padding-left: 46px;
  }
  .game-title h2 {
    font-size: 36px;
  }
}
